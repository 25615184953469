body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #FFF;
  background-image: url(./img/sfondo.jpg);
  background-size: 100% auto;
  background-repeat: repeat-y;
}

p {
  margin-bottom: 10px;
}

.main {
  position: relative;
  overflow: hidden;
}

.header {
  min-height: 50px;
  padding-left: 8%;
  padding-right: 8%;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

.presale-intro {
  margin-bottom: 0;
  color: #FA7CB7;
  font-size: 20px;
}

.presale-title {
  margin: 10px 40px 30px;
  padding: 5px;
  color: #FFF;
  background-color: #FA7CB7;
  font-size: 50px;
  font-weight: bold;
  border-radius: 5px;
}

.presale-subtitle {
  margin-bottom: 80px;
}

.sale-title {
  margin: 0px 40px 50px;
  padding: 5px;
  color: #FFF;
  background-color: #FA7CB7;
  font-size: 50px;
  font-weight: bold;
  border-radius: 5px;
}

.sale-subtitle {
  margin-bottom: 80px;
}

.affiliation-title {
  margin: 0px 40px 60px;
  padding: 5px;
  color: #FFF;
  background-color: #FA7CB7;
  font-size: 50px;
  font-weight: bold;
  border-radius: 5px;
}

.item-price {
  font-weight: 600;
}

.item-image {
  padding: 10px;
  border-radius: 5px;
}

.item-button-panel {
  margin-bottom: 80px;
}

.item-button {
  color: #FFF;
  border-radius: 10px;
}

.item-button-buy, .item-button-buy:hover {
  margin-right: 10px;
  background-color: #FA7CB7;
}

.item-button-os, .item-button-os:hover {
  background-color: #2081E2;
}

.item-button-affiliate, .item-button-affiliate:hover {
  margin: 20px 0;
  background-color: #FA7CB7;
}

.member-input {
  width: 100%;
}
